export const Constants = {
  standardApplicationTypes: [1, 2, 3], // Standard application types are RP, MP and AS
  viewType: {
    visible: 1,
    hidden: 2,
  },
  modelGroupId: 1,
  certificationsId: 2,
  swFiles: 'Software',
  docFiles: 'Documents',
  defaultSelected: 'x',
  defaultDateFormat: 'fr-FR',
  andLaterLevel: 9999, // Tags with level 9999 is for the "and later" tag
  status: {
    updated: 'updated',
    unchanged: 'unchanged',
    deleted: 'deleted',
    new: 'new',
    edited: 'edited',
  },
  routes: {
    root: '/',
    myApplications: '/my-applications',
    downloadWithId: '/type/:type/download/:id?',
    about: '/type/:type/about',
    login: '/idmslogin',
    logout: '/idmslogout',
    cancel: '/idmsprofilecancel',
    addApplication: '/add-application/',
    editApplicationWithId: '/type/:type/edit-application/:id?',
    editApplication: '/edit-application/',
    statistics: '/statistics',
    caValues: '/cavalues',
    feedbackList: '/feedbackList',
  },
  cache: {
    user: 'user',
    route: 'route',
    activeTabs: 'activeTabs',
    myAppsForm: 'myAppsForm',
    selectorForm: 'selectorForm',
  },
  extensions: ['pdf', 'xls', 'xlsx', 'xml', 'doc', 'docx', 'zip'],

  genericApplicationsUploadForm: {
    active: {
      name: 'Active',
      label: 'Item Status',
      selected: 1,
      valid: true,
      multipleSelection: false,
      options: [
        {
          value: 1,
          label: 'Published - available for all users',
          isActive: true,
        },
        {
          value: 0,
          label: 'Not published - only visible for uploaders',
          isActive: false,
        },
      ],
    },
    name: {
      name: 'Name',
      placeHolder: 'A name for the uploader to identify the item',
      label: 'Name',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
        isUnique: true,
      },
    },
    description: {
      name: 'Description',
      placeHolder: 'A brief concise text explaining the main functionality',
      label: 'Description',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    version: {
      name: 'Version',
      placeHolder: '',
      label: 'Software version number',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    releaseNotes: {
      name: 'ReleaseNote',
      placeHolder: '',
      label: 'Release notes',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
  },
  uploadForm: {
    name: {
      name: 'Name',
      placeHolder: 'A name for the uploader to identify the item',
      label: 'Name',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
        isUnique: true,
      },
    },
    description: {
      name: 'Description',
      placeHolder: 'A brief concise text explaining the main functionality (see template how to write)',
      label: 'Description',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    version: {
      name: 'Version',
      placeHolder: '',
      label: 'Software version number',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    releaseNotes: {
      name: 'ReleaseNote',
      placeHolder: '',
      label: 'Release notes',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    firmWare: {
      name: 'Firmware',
      placeHolder: '',
      label: 'Firmware version number',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    customType: {
      name: 'CustomType',
      placeHolder: '',
      label: 'Custom Type version number',
      valid: false,
      touched: false,
      validationRules: {
        isRequired: true,
      },
    },
    active: {
      name: 'Active',
      label: 'Item Status',
      selected: 1,
      valid: true,
      multipleSelection: false,
      options: [
        {
          value: 1,
          label: 'Published - available for all users',
          isActive: true,
        },
        {
          value: 0,
          label: 'Not published - only visible for uploaders',
          isActive: false,
        },
      ],
    },
  },
};

export enum ErrorCodes {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
}

export function isStandardApplication(appTypeId) {
  return Constants.standardApplicationTypes.includes(appTypeId);
}

// TODO: Check for ID instead.
export function isSoftwareGroup(group) {
  return group?.name === Constants.swFiles;
}
